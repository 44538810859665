/* responsive.less */

// Mobile
@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 770px){
  .skip-cart {
    .count {
      background: @colorWasgauGreen;
      color:white;
    }
    &:hover .count {
      background: @colorWasgauGreenLight !important;
    }
  }
}