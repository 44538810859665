/* View: Product-Detail-View */

.product-view {
  .price-box {
    .price {
      color: @colorGrey;
    }
  }
}
.product-collateral {
  .toggle-tabs {
    li {
      &.current,
      &:hover {
        span {
          color: @colorWasgauGreen;
        }
      }
    }
  }
}