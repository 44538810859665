/* view-product-list.less */

/* Price */
.price-box {
  .price {
    color: @colorGrey  !important;
  }
}

/* Image */
.no-touch .product-image {
  &:hover {
    border-color: @colorWasgauGreen;
  }
}

/* Pages Next/Previous */
.pages {
  .next {
    &:before {
      border-left-color: @colorWasgauGreen;
    }
    &:hover {
      border-color: @colorWasgauGreenLight;
      &:before {
        border-left-color: @colorWasgauGreenLight;
      }
    }
  }
  .previous {
    &:before {
      border-right-color: @colorWasgauGreen;
    }
    &:hover {
      border-color: @colorWasgauGreenLight;
      &:before {
        border-right-color: @colorWasgauGreenLight;
      }
    }
  }
}

/* Structure of List-Items */
#products-list {
  .product-shop {
    width: 100%;
    .product_add_to_cart {
      input#qty {
        width: 50px;
        margin-bottom: 5px;
      }
    }
  }
  .item {
    .product-image {
      display: none;
    }
    .price-box .special-price {
      padding-left: 0;
    }
    .old-price {display: none}
  }
}
