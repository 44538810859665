/* header.less */

.header-language-background {
  background-color: @colorWasgauGreen;
}
.currency-switcher {display: none}
.download-offline-ordner-sheet {
  color: white !important;
  margin-left: 20px;
}
.store-language-container #easyflags {
  a {
    display: inline-block;
  }
}