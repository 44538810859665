/* buttons.less */

.button {
  background: @colorWasgauGreen !important;
  color: white !important;
  &:hover {
    background: @colorWasgauGreenLight !important;
  }
}
.button2 {
  span,
  span span {
    color: @colorWasgauGreen;
  }
}

button.multiple-add-to-cart {
  background-color: @colorWasgauOrange !important;
  color: white !important;
  &:hover {
    background: @colorWasgauOrangeLight !important;
  }
}

a.button {
  &:hover,
  &:active,
  &:focus {
    color: white !important;
    text-decoration: none;
  }
}