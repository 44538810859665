/* globals.less */

body {
  background-color: white;
}

/* Global Classes */
.clear {clear: both}
.left {float: left}
.right {float: right}

/* Header */
h1, h2, h3 {
  color: @colorGrey !important;
}
span {
  &.h1 {
    color: @colorGrey !important;
  }
}

/* Link */
a {
  color: @colorWasgauGreen !important;
  &:hover,
  &:active,
  &:focus {
    color: @colorWasgauGreenLight !important;
    text-decoration: none;
  }
}

/* Numbers */
.opc .section .step-title .number,
.opc .section.allow.active .step-title .number,
.no-touch .opc .section.allow:hover .step-title .number {
  background-color: @colorWasgauGreen;
}

.opc .section.allow .step-title .number {
  background-color: @colorWasgauGreenLight;
}

/* Icons */
.icon-sprite,
.btn-remove2,
.ratings .rating-box,
.ratings .rating-box .rating,
.sorter > .sort-by .sort-by-switcher,
.sorter > .view-mode .grid,
.sorter > .view-mode .list,
.skip-nav .icon, .skip-search .icon,
#search_mini_form .search-button:before,
.skip-account .icon, .skip-cart .icon,
.product-view .sharing-links a,
.review-summary-table .rating-box .rating-number:after,
.ratings-table .rating-box,
.ratings-table .rating-box .rating {
  background-image: url(../images/icon_sprite.png);
}

/* Minicart */
.header-minicart {
  .block-subtitle {
    color: @colorGrey;
  }
  .subtotal {
    .price {
      color: @colorGrey;
    }
  }
  .info-wrapper {
    td {
      color: @colorGrey;
    }
  }
}
.skip-cart,
.skip-link.skip-active {
  .count {
    color: @colorGrey;
  }
}

/* Add to Links / Sharing Links */
.add-to-links,
.sharing-links,
.email-friend,
.no-rating {
  display: none !important;
}

/* Highlight Manager Specials */
li.level0.nav-3 {
  background-color: @colorWasgauOrangeLighter;
  a {
    color: @colorWasgauOrange !important;
    &:hover,
    &:active,
    &:focus {
      color: @colorWasgauOrangeLight !important;
      text-decoration: none;
    }
  }
}


.pre-order-article .clickable {
  cursor: pointer;
}